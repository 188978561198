<template>
  <div>
    <div class="card-body bg-white pb-2 p-relative pt-5">
      <img src="/assets/img/icons/share-alt-light-black.svg" alt="" class="share-icon">
      <FlightDesData>
        <template slot="additionalData">
          <slot name="actionPart"></slot>
        </template>
      </FlightDesData>
      <div class="d-flex flex-column align-items-center">
        <div class="mt-10 fs14 text-center">
          Price: <b class="fs16">300 ₪</b>
        </div>
        <router-link
          :to="{
            name: 'FlightOrderSummary',
          }"
        >
          <button class="primary-btn small-btn fs16 bg-green6">
            {{ $t( "Book Flight" ) }}
          </button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import CategoryShare from '../../CategoryShare';
import FlightDesData from '../FlightDesData';

export default {
  name: "FlightDesItem",
  props: {
  },
  components: {
    CategoryShare,
    FlightDesData,
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
  .fs16 {
    font-size: 16px !important;
  }
  .share-icon {
    position: absolute;
    right: 12px;
    bottom: 12px;
    height: 20px;
  }
</style>