<template>
  <div class="mobile-slider-wrapper">
    <carousel 
      class="cus-slider"
      :per-page="1" 
      :mouse-drag="false"
      :navigationEnabled="true"
      :paginationEnabled="false"
      :navigation-next-label="nextLabel"
      :navigation-prev-label="prevLabel"
    >
      <slide>
        <img src="/assets/img/banner/01.jpg" alt="">
      </slide>
      <slide>
        <img src="/assets/img/banner/590.jpg" alt="">
      </slide>
      <slide>
        <img src="/assets/img/banner/65967.jpg" alt="">
      </slide>
    </carousel>
  </div>
</template>

<script>
export default {
  name: "MobileBannerCarousel",
  data() {
    return {
      nextLabel: "<img class src='/assets/img/chevron.svg' />",
      prevLabel: "<img class src='/assets/img/chevron.svg' />"
    }
  }
}
</script>